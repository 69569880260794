
import { defineComponent, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code8 } from "./data";

export default defineComponent({
  name: "mixed-input",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      input1: ref(""),
      input2: ref(""),
      input3: ref(""),
      select: ref(""),
      code8
    };
  }
});
