
import { defineComponent, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code6 } from "./data";

export default defineComponent({
  name: "textarea",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      textarea: ref(""),
      code6
    };
  }
});
