
import { defineComponent, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code13 } from "./data";

export default defineComponent({
  name: "limit-length",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      text: ref(""),
      textarea: ref(""),
      code13
    };
  }
});
